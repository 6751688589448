<template>
    <div class="fm-style st-page">
        <b-form ref="generateForm" novalidate>
            <template v-for="item in data.list">
                <template v-if="item.type == 'grid'">
                    <b-row
                        :key="item.key"
                        type="flex"
                        :gutter="item.options.gutter ? item.options.gutter : 0"
                        :justify="item.options.justify"
                        :align="item.options.align"
                    >
                        <b-col
                            v-for="(col, colIndex) in item.columns"
                            :key="colIndex"
                            :cols="col.span ? col.span : 0"
                        >
                            <template>
                                <generate-form-item v-for="citem in col.list"
                                    :key="citem.key"
                                    :models.sync="models"
                                    :rules="rules"
                                    :widget="citem"
                                    :viewMode="viewMode"
                                    :partialEdit="partialEdit"
                                    :validate="wasValidated"
                                    :validation="wasValidated ? validation[citem.name] : null"
                                    :validationMessage="validationMessage[citem.name]"
                                    @input-change="onInputChange"
                                >
                                </generate-form-item>
                            </template>
                        </b-col>
                    </b-row>
                </template>
                <template v-else-if="item.type === 'inline'">
                    <div class="fm-inline-container" :key="item.key">
                        <template>
                            <generate-form-item v-for="el in item.list"
                                :key="`form_gen_${el.key}`"
                                :models.sync="models"
                                :rules="rules"
                                :widget="el"
                                :viewMode="viewMode"
                                :partialEdit="partialEdit"
                                :validate="wasValidated"
                                :validation="wasValidated ? validation[el.name] : null"
                                :validationMessage="validationMessage[el.name]"
                                @input-change="onInputChange"
                            >
                            </generate-form-item>
                        </template>
                    </div>
                </template>
                <template v-else-if="item.type === 'section'">
                    <st-section
                        :key="item.key"
                        :header="item.options.title"
                        :collapsible="item.options.collapsible"
                        :isCollapsed="item.options.isCollapsed"
                        :collapsibleId="item.key"
                    >
                        <template #body>
                            <template v-for="el in item.list">
                                <template v-if="el.type == 'grid'">
                                    <b-row
                                        :key="el.key"
                                        type="flex"
                                        :gutter="el.options.gutter ? el.options.gutter : 0"
                                        :justify="el.options.justify"
                                        :align="el.options.align"
                                    >
                                        <b-col
                                            v-for="(col, colIndex) in el.columns"
                                            :key="colIndex"
                                            :cols="col.span ? col.span : 0"
                                        >
                                            <template>
                                                <generate-form-item v-for="citem in col.list"
                                                    :key="citem.key"
                                                    :models.sync="models"
                                                    :rules="rules"
                                                    :widget="citem"
                                                    :viewMode="viewMode"
                                                    :partialEdit="partialEdit"
                                                    :validate="wasValidated"
                                                    :validation="wasValidated ? validation[citem.name] : null"
                                                    :validationMessage="validationMessage[citem.name]"
                                                    @input-change="onInputChange"
                                                >
                                                </generate-form-item>
                                            </template>
                                        </b-col>
                                    </b-row>
                                </template>
                                <template v-else-if="el.type === 'inline'">
                                    <div
                                        class="fm-inline-container"
                                        :key="el.key"
                                    >
                                        <template>
                                            <generate-form-item v-for="eli in el.list"
                                                :key="`form_gen_${eli.key}`"
                                                :models.sync="models"
                                                :rules="rules"
                                                :widget="eli"
                                                :viewMode="viewMode"
                                                :partialEdit="partialEdit"
                                                :validate="wasValidated"
                                                :validation="wasValidated ? validation[eli.name]: null"
                                                :validationMessage="validationMessage[eli.name]"
                                                @input-change="onInputChange"
                                            >
                                            </generate-form-item>
                                        </template>
                                    </div>
                                </template>
                                <template v-else-if="el.type === 'related-application'">
                                    <related-application
                                        :maxFileSize="Number(el.options.maxFileSize)"
                                        :acceptedFileTypes="el.options.acceptedFileTypes"
                                        :maxFiles="Number(el.options.maxFiles)"
                                        :showLabel="!el.options.hideLabel"
                                        :label="el.label"
                                        :isRequired="el.options.required"
                                        :viewMode="viewMode"
                                        :docKey="el.options.docKey"
                                        :autocompleteForm="el.options.autocompleteForm"
                                        :loadGuestApplications="el.options.loadGuestApplications"
                                        :loadExternalDocuments="el.options.loadExternalDocuments"
                                        :loadAllOrganisationsApplications="el.options.loadAllOrganisationsApplications"
                                        :condition="el.condition"
                                        ref="relatedApplication"
                                        :key="`form_gen_${el.key}_related_application`"
                                        @change="updateModel"
                                        :wasValidated="wasValidated"
                                    >
                                    </related-application>
                                </template>
                                <generate-form-item
                                    v-else
                                    :key="`form_gen_${el.key}`"
                                    :models.sync="models"
                                    :rules="rules"
                                    :widget="el"
                                    :viewMode="viewMode"
                                    :partialEdit="partialEdit"
                                    :validate="wasValidated"
                                    :validation="wasValidated ? validation[el.name] : null"
                                    :validationMessage="validationMessage[el.name]"
                                    :fileToken="fileToken"
                                    @input-change="(value, field) => onInputChange(value, field, item)"
                                >
                                </generate-form-item>
                            </template>
                        </template>
                    </st-section>
                </template>

                <template v-else-if="item.type === 'beneficiary-list'">
                    <beneficiary-list
                        ref="beneficiaryList"
                        :key="`form_gen_${item.key}_beneficiary`"
                        :viewMode="viewMode"
                        @changeList="updateModel"
                        :wasValidated="wasValidated"
                    />
                </template>

                <template v-else-if="item.type === 'applicant-form'">
                    <applicant-form
                        ref="applicantForm"
                        :key="`form_gen_${item.key}_applicant`"
                        :viewMode="viewMode"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else-if="item.type === 'real-estate-list'">
                    <real-estate-list
                        ref="realEstateList"
                        :key="`form_gen_${item.key}_real_estate`"
                        :viewMode="viewMode"
                        :applicationType="value.application_type_id"
                        :wasValidated="wasValidated"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else-if="item.type === 'decision-list'">
                    <application-decision-list
                        :viewMode="viewMode"
                        ref="decisionList"
                        :key="`form_gen_${item.key}_decision`"
                        :wasValidated="wasValidated"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else-if="item.type === 'attachments'">
                    <application-attachments
                        :maxFileSize="Number(item.options.maxFileSize)"
                        :acceptedFileTypes="item.options.acceptedFileTypes"
                        :maxFiles="Number(item.options.maxFiles)"
                        :viewMode="viewMode"
                        :title="item.label"
                        ref="attachments"
                        :key="`form_gen_${item.key}_attach`"
                        :requireAttachments="requireAttachments"
                        @changeList="updateModel"
                    >
                    </application-attachments>
                </template>

                <template v-else-if="item.type === 'urbanism-regulation-picker'">
                    <application-urbanism-regulation
                        ref="urbanismRegulations"
                        :key="`form_gen_${item.key}_rlu`"
                        :partialEdit="partialEdit"
                    >
                    </application-urbanism-regulation>
                </template>

                <template v-else-if="item.type === 'related-application'">
                    <related-application
                        :maxFileSize="item.options.maxFileSize"
                        :acceptedFileTypes="item.options.acceptedFileTypes"
                        :maxFiles="item.options.maxFiles"
                        :showLabel="!item.options.hideLabel"
                        :label="item.label"
                        :isRequired="item.options.required"
                        :viewMode="viewMode"
                        :docKey="item.options.docKey"
                        :autocompleteForm="item.options.autocompleteForm"
                        :loadGuestApplications="item.options.loadGuestApplications"
                        :loadExternalDocuments="item.options.loadExternalDocuments"
                        :loadAllOrganisationsApplications="el.options.loadAllOrganisationsApplications"
                        :condition="item.condition"
                        ref="relatedApplication"
                        :key="`form_gen_${item.key}_related_application`"
                        @change="updateModel"
                        :wasValidated="wasValidated"
                    >
                    </related-application>
                </template>

                <template v-else-if="item.type === 'realty-list'">
                    <realty-list
                        ref="realtyList"
                        :key="`form_gen_${item.key}_realty`"
                        :viewMode="viewMode"
                        :wasValidated="wasValidated"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else-if="item.type === 'offender-list'">
                    <offender-list
                        ref="offenderList"
                        :key="`form_gen_${item.key}_offender`"
                        :viewMode="viewMode"
                        :wasValidated="wasValidated"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else-if="item.type === 'witness-list'">
                    <witness-list
                        ref="witnessList"
                        :key="`form_gen_${item.key}_witness`"
                        :viewMode="viewMode"
                        :wasValidated="wasValidated"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else-if="item.type === 'staff-form'">
                    <staff-form
                        ref="staffForm"
                        :key="`form_gen_${item.key}_staff`"
                        :viewMode="viewMode"
                        :wasValidated="wasValidated"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else-if="item.type === 'gis-attachments'">
                    <application-gis-attachments
                        :maxFileSize="Number(item.options.maxFileSize)"
                        :acceptedFileTypes="item.options.acceptedFileTypes"
                        :maxFiles="Number(item.options.maxFiles)"
                        :viewMode="viewMode"
                        :title="item.label"
                        ref="gis-attachments"
                        :key="`form_gen_${item.key}_gis_attach`"
                        @changeList="updateModel"
                    >
                    </application-gis-attachments>
                </template>

                <template v-else-if="item.type === 'ac-tax-validator'">
                    <ac-tax-validator
                        ref="acTaxValidator"
                        :title="item.label"
                        :coeff="item.options.coeff"
                        :taxCategories="item.options.acTaxCategories"
                        :viewMode="viewMode"
                        :wasValidated="wasValidated"
                        :key="`form_gen_${item.key}_ac_tax`"
                        @change="updateModel"
                    ></ac-tax-validator>
                </template>

                <template v-else-if="item.type === 'locality-list'">
                    <locality-list
                        ref="localityList"
                        :key="`form_gen_${item.key}_locality`"
                        :viewMode="viewMode"
                        :wasValidated="wasValidated"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else-if="item.type === 'buildings-list'">
                    <buildings-list
                        ref="buildingsList"
                        :key="`form_gen_${item.key}_buildings`"
                        :viewMode="viewMode"
                        :wasValidated="wasValidated"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else-if="item.type === 'urbanism-regulations-list'">
                    <urbanism-regulations-list
                        ref="urbanismRegulationsList"
                        :key="`form_gen_${item.key}_urbanism_regulations_list`"
                        :viewMode="viewMode"
                        :wasValidated="wasValidated"
                        :humanReview="humanReview"
                        :partialEdit="partialEdit"
                        :selectedUtrDocumentTemplate="item.options.selectedUtrDocumentTemplate"
                        @changeList="updateModel"
                    />
                </template>

                <template v-else>
                    <generate-form-item
                        :key="`form_gen_${item.key}`"
                        :models.sync="models"
                        :rules="rules"
                        :widget="item"
                        :viewMode="viewMode"
                        :partialEdit="partialEdit"
                        :validate="wasValidated"
                        :validation="wasValidated ? validation[item.name] : null"
                        :validationMessage="validationMessage[item.name]"
                        @input-change="onInputChange"
                    >
                    </generate-form-item>
                </template>
            </template>
        </b-form>
    </div>
</template>

<script>
import GenerateFormItem from "./GenerateFormItem";
import convertNumberToWordsRomanian from "@/shared/utils/number-to-text";
import sumNumbers from "@/shared/utils/sum-numbers";
import multiplyNumbers from "@/shared/utils/multiply-numbers";
import moment from 'moment';

const CONDITION_FILTERS = {
    eq: (model, field, value) => {
        return model[field] === value;
    },
    neq: (model, field, value) => {
        return model[field] !== value;
    },
    includes: (model, field, value) => {
        const arrayOfValues = value.split(",");
        return arrayOfValues.some((el) => model[field].includes(el.trim()));
    },
    not_includes: (model, field, value) => {
        const arrayOfValues = value.split(",");
        return !arrayOfValues.some((el) => model[field].includes(el.trim()));
    },
    gt: (model, field, value) => {
        return Number(model[field]) > Number(value);
    },
    lt: (model, field, value) => {
        return Number(model[field]) < Number(value);
    },
    gte: (model, field, value) => {
        return Number(model[field]) >= Number(value);
    },
    lte: (model, field, value) => {
        return Number(model[field]) <= Number(value);
    },
};
export default {
    name: "fm-generate-form",
    components: {
        GenerateFormItem,
        BeneficiaryList: () => import("@/modules/applications/components/beneficiary/BeneficiaryList.vue"),
        RealEstateList: () => import("@/modules/applications/components/real-estate/RealEstateList.vue"),
        ApplicantForm: () => import("@/modules/applications/components/applicant/ApplicantForm.vue"),
        ApplicationDecisionList: () => import("@/modules/applications/components/decision/ApplicationDecisionList.vue"),
        ApplicationAttachments: () => import("@/modules/applications/components/attachments/ApplicationAttachments.vue"),
        ApplicationUrbanismRegulation: () => import("@/modules/applications/components/urbanism-regulation/ApplicationUrbanismRegulation.vue"),
        RelatedApplication: () => import("@/modules/applications/components/related-application/RelatedApplication.vue"),
        RealtyList: () => import("@/modules/control/components/realty/RealtyList.vue"),
        OffenderList: () => import("@/modules/control/components/offender/OffenderList.vue"),
        WitnessList: () => import("@/modules/control/components/witness/WitnessList.vue"),
        StaffForm: () => import("@/modules/control/components/staff/StaffForm.vue"),
        ApplicationGisAttachments: () => import("@/modules/applications/components/gis-attachments/ApplicationGisAttachments.vue"),
        AcTaxValidator: () => import("@/modules/applications/components/ac-tax-validator/AcTaxValidator.vue"),
        LocalityList: () => import("@/modules/applications/components/localities/LocalityList.vue"),
        BuildingsList: () => import("@/modules/applications/components/buildings/BuildingsList.vue"),
        UrbanismRegulationsList: () => import("@/modules/applications/components/urbanism-regulations-edit/UrbanismRegulationsList.vue"),
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        value: {
            type: Object,
            default: () => {},
        },
        viewMode: {
            type: Boolean,
            default: false,
        },
        partialEdit: {
            type: Boolean,
            default: false,
        },
        humanReview: {
            type: Boolean,
            default: false,
        },
        fileToken: {
            type: String,
            require: false,
        },
        requireAttachments: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            models: {},
            rules: {},
            modelOptions: {},
            validation: {},
            validationMessage: {},
            wasValidated: false,
            fieldsVisibility: {},
        };
    },
    watch: {
        data: {
            deep: true,
            handler(val) {
                this.generateModel(val.list);
            },
        },
        value: {
            deep: true,
            handler(val) {
                delete this.models?.options;
                delete val?.options;
                this.models = { ...this.models, ...val };
            },
        },
    },
    created() {
        this.generateModel(this.data.list);
    },
    mounted() {
        this.$nextTick(() => {
            this.$emit("child-mounted");
        });
    },
    methods: {
        updateModel(data, key, realEstateTargetUsed) {
            this.models[key] = data;
            this.$emit("modelUpdated", this.models[key], key, realEstateTargetUsed);
        },
        generateModel(genList) {
            for (let i = 0; i < genList.length; i++) {
                if (genList[i].type === "grid") {
                    genList[i].columns.forEach((item) => {
                        this.generateModel(item.list);
                    });
                } else if (["section", "inline"].includes(genList[i].type)) {
                    this.generateModel(genList[i].list);
                } else {
                    if (genList[i].type === "related-application" ) {
                        const key = `related_application${genList[i].options.docKey}`
                        this.models[key] = this.value[key];
                    } else if (
                        this.value &&
                        Object.keys(this.value).indexOf(genList[i].name) >= 0
                    ) {
                        this.models[genList[i].name] = this.value[genList[i].name];
                    } else {
                      if (!genList[i].isConditioned) {
                        this.models[genList[i].name] = genList[i].options.defaultValue;
                      }
                    }

                    if (genList[i].type === "checkbox") {
                        this.modelOptions[genList[i].name] = genList[i].options.options.map((el) => el.value);
                    }

                    if (genList[i].type === "select") {
                        this.modelOptions[genList[i].name] = {
                            type: "select",
                            options: genList[i].options.options.map((el) => {
                                return { value: el.value, label: el.label };
                            }),
                        };
                    }

                    if (this.rules[genList[i].name]) {
                        this.rules[genList[i].name] = [
                            ...this.rules[genList[i].name],
                            ...genList[i].rules.map((item) => {
                                if (item.pattern) {
                                    return {
                                        ...item,
                                        pattern: item.pattern,
                                    };
                                } else {
                                    return { ...item };
                                }
                            }),
                        ];
                    } else {
                        this.rules[genList[i].name] = [
                            ...genList[i].rules.map((item) => {
                                const {
                                  isConditioned,
                                  condition,
                                  isAdvancedConditions,
                                  conditionField,
                                  conditionOperation,
                                  conditionValue
                                } = genList[i];
                                let ruleItem = {
                                  ...item,
                                };
                                if (item.pattern) {
                                    ruleItem = {
                                      ...ruleItem,
                                      pattern: item.pattern,
                                  };
                                }
                                if (isConditioned) {
                                  ruleItem = {
                                    ...ruleItem,
                                    isConditioned,
                                    condition
                                  };
                                }
                                if (isAdvancedConditions) {
                                  ruleItem = {
                                    ...ruleItem,
                                    isAdvancedConditions,
                                    conditionField,
                                    conditionOperation,
                                    conditionValue
                                  };
                                }
                                return ruleItem;
                            }),
                        ];
                    }
                }
            }
        },
        getData(draft) {
            return new Promise(async (resolve, reject) => {
                let formIsValid = true;
                let customValues = {};

                //validate custom components
                if (this.$refs.beneficiaryList) {
                    formIsValid = formIsValid && this.$refs.beneficiaryList[0].items.length > 0;
                    customValues.beneficiary_users = this.$refs.beneficiaryList[0].items;
                }

                if (this.$refs.realEstateList) {
                    formIsValid = formIsValid && this.$refs.realEstateList[0].items.length > 0;
                    customValues.real_estate_target = this.$refs.realEstateList[0].items;
                }

                if (this.$refs.applicantForm) {
                    const isValid = await this.$refs.applicantForm[0].$refs.applicantForm.fv.validate();
                    formIsValid = formIsValid && (isValid === "Valid");

                    customValues.applicant_user = this.$refs.applicantForm[0].$refs.applicantForm.model;
                }

                if (this.$refs.decisionList) {
                    formIsValid = formIsValid && this.$refs.decisionList[0].items.length > 0;
                    customValues.decisions = this.$refs.decisionList[0].items;
                }

                if (this.$refs.relatedApplication) {
                    for (let i = 0; i < this.$refs.relatedApplication.length; i++) {
                        const isValid = await this.$refs.relatedApplication[i].fv.validate();
                        formIsValid = formIsValid && (isValid === "Valid" && this.$refs.relatedApplication[i].isDocumentUploadedValid());
                    }
                }

                if (this.$refs.offenderList) {
                    formIsValid = formIsValid && this.$refs.offenderList[0].items.length > 0;
                    customValues.offender = this.$refs.offenderList[0].items;
                }

                if (this.$refs.witnessList) {
                    formIsValid = formIsValid && this.$refs.witnessList[0].items.length > 0;
                    customValues.witness = this.$refs.witnessList[0].items;
                }

                if (this.$refs.staffForm) {
                    const isValid = await this.$refs.staffForm[0].fv.validate();
                    formIsValid = formIsValid && (isValid === "Valid");
                    customValues.staff = this.$refs.staffForm[0].model;
                }

                if (this.$refs.realtyList) {
                    formIsValid = formIsValid && this.$refs.realtyList[0].items.length > 0;
                    customValues.realty = this.$refs.realtyList[0].items;
                }

                if (this.$refs.acTaxValidator) {
                    const isValid = await this.$refs.acTaxValidator[0].fv.validate();
                    formIsValid = formIsValid && (isValid === "Valid");
                }

                if (this.$refs.localityList) {
                    formIsValid = formIsValid && this.$refs.localityList[0].items.length > 0;
                    // customValues.localityList = this.$refs.localityList[0].items;
                }

                if (this.$refs.buildingsList) {
                    formIsValid = formIsValid && this.$refs.buildingsList[0].items.length > 0;
                    // customValues.buildingsList = this.$refs.buildingsList[0].items;
                }

                if (this.$refs.urbanismRegulationsList) {
                    formIsValid = formIsValid && this.$refs.urbanismRegulationsList[0].items.length > 0;
                    customValues.urbanism_regulations = this.$refs.urbanismRegulationsList[0].items;
                }

                let requiredFieldsValid = this.validateRequiredFields();

                if (draft) {
                    requiredFieldsValid = true;
                    formIsValid = true;
                    this.wasValidated = false;
                } else {
                    this.wasValidated = true;
                }

                if (formIsValid && requiredFieldsValid) {
                    let clearModel = {};
                    Object.keys(this.models).forEach((key) => {
                        if (this.models[key] != undefined) {
                            let initialValue = this.models[key];
                            if (typeof initialValue === "string") {
                                initialValue = initialValue.replace(/(?:\r\n|\n|\r)/g, "\r\n");

                                const hasMoreThanAscii = /\u0002/g.test(initialValue);

                                if (hasMoreThanAscii) {
                                    initialValue = initialValue.replace(/\u0002/g, '');
                                }
                            }

                            let obj = {
                                [key]: initialValue,
                            };

                            clearModel = {
                                ...clearModel,
                                ...obj,
                            };
                        }
                    });

                    const form = {
                        ...clearModel,
                        ...customValues
                    };

                    if (this.$refs.attachments?.documents?.length) {
                        form.attachments = this.$refs.attachments;
                    }

                    const model = {
                        form,
                        options: this.modelOptions,
                    };
                    resolve(model);
                } else {
                    reject("VALIDATION_ERROR");

                    this.$alert({
                        type: "warning",
                        text: this.$t("FORMS.MESSAGE.VALID_ERROR"),
                        showConfirmButton: false,
                    });
                }
            });
        },
        clearGenerateFormData(data) {
            this.models = {...this.models, ...data.form};
        },
        reset() {
            this.$refs.generateForm.resetFields();
        },
        onInputChange(value, field, widget) {
            if (this.wasValidated) {
                this.validateRequiredFields();
            }
            if (widget && widget.options?.calculator) {
                widget.list.forEach(item => {
                    if (item.options.convertNumberToTextFromField === field) {
                        const res = convertNumberToWordsRomanian(value);
                        if (res) {
                            this.models[item.name] = res;
                            this.$emit("modelUpdated", res, item.name);
                        }
                    } else if (item.options?.sumNumbersFromFields) {
                        const fields = item.options.sumNumbersFromFields?.split(',').map(f => f.trim());
                        if (fields.indexOf(field) > -1) {
                            const values = fields.map(f => Number(this.models[f]));
                            const res = sumNumbers(values);
                            if (res) {
                                this.models[item.name] = res;
                                this.$emit("modelUpdated", res, item.name);
                            }
                        }
                    } else if (item.options?.multiplyNumbersFromFields) {
                        const fields = item.options.multiplyNumbersFromFields?.split(',').map(f => f.trim());
                        if (fields.indexOf(field) > -1) {
                            const values = fields.map(f => Number(this.models[f]));
                            const res = multiplyNumbers(values);
                            if (res) {
                                this.models[item.name] = res;
                                this.$emit("modelUpdated", res, item.name);
                            }
                        }
                    
                    } else if (item.options?.sumMonths) {
                        const fields = item.options.sumMonthsFromFields?.split(',').map(f => f.trim());
                        if (fields.indexOf(field) > -1) {
                            const dateField = fields.find(f => {
                                const value = this.models[f];
                                return moment(value).isValid();
                            });
                            const monthsField = fields.find(f => {
                                const value = this.models[f];
                                return !isNaN(value);
                            });
                            const dateValue = this.models[dateField];
                            const monthsValue = Number(this.models[monthsField]);
                            const res = moment(dateValue).add(monthsValue, 'months').format("YYYY-MM-DD");
                            if (res) {
                                this.models[item.name] = res;
                                this.$emit("modelUpdated", res, item.name);
                            }
                        }
                    } else if (item.options?.diffMonths) {
                        const fields = item.options.diffMonthsFromFields?.split(',').map(f => f.trim());
                        if (fields.indexOf(field) > -1) {
                            const startDateField = fields[0];
                            const endDateField = fields[1];
                            const startDateValue = this.models[startDateField];
                            const endDateValue = this.models[endDateField];
                            if (startDateValue && endDateValue) {
                                const res = moment(endDateValue, "YYYY-MM-DD").diff(moment(startDateValue, "YYYY-MM-DD"), 'months') + 1;
                                if (res) {
                                    this.models[item.name] = res;
                                    this.$emit("modelUpdated", res, item.name);
                                }
                            }
                        }
                    }
                })
            }
            this.$emit("modelUpdated", value, field);
        },
        refresh() {},
        validateRequiredFields() {
            const requiredFields = this.getRequiredFields();
            let isValid = true;
            this.validation = requiredFields.reduce(
                (acc, cur) => ({ ...acc, [cur]: null }),
                {}
            );
            requiredFields.forEach((field) => {
                const requiredRuleField = this.rules[field].find(el => el.required);
                if (requiredRuleField) {
                  this.validationMessage[field] = requiredRuleField.message;
                  const needValidation = (!requiredRuleField?.isConditioned || (requiredRuleField?.isConditioned && this.isFieldVisible(requiredRuleField))) &&
                  (!requiredRuleField.isAdvancedConditions || (requiredRuleField.isAdvancedConditions && this.isFieldVisible(requiredRuleField)));
                  if (needValidation) {
                    if (!this.models[field] || !this.models[field]?.length) {
                        isValid = false;
                        this.validation[field] = false;
                    } else {
                        this.validation[field] = true;
                    }
                  }
                }
                const patternRuleField = this.rules[field].find(el => el.pattern);
                if (patternRuleField) {
                    this.validationMessage[field] = patternRuleField.message;
                    const needValidation = (!patternRuleField?.isConditioned || (patternRuleField?.isConditioned && this.isFieldVisible(patternRuleField))) &&
                    (!patternRuleField.isAdvancedConditions || (patternRuleField.isAdvancedConditions && this.isFieldVisible(patternRuleField)));
                    if (needValidation && this.models[field]) {
                        const regExp = new RegExp(patternRuleField.pattern);
                        const testRes = regExp.test(this.models[field]);
                        isValid = testRes;
                        this.validation[field] = testRes;
                    }
                }
            });
            return isValid;
        },
        isFieldVisible(field) {
          if (field?.isConditioned) {
              const [name, value] = field.condition.split("=");
              return this.models[name] === value;
          }
          if (field?.isAdvancedConditions) {
              const {
                  conditionField,
                  conditionOperation,
                  conditionValue,
              } = field;

              return CONDITION_FILTERS[conditionOperation](
                  this.models,
                  conditionField,
                  conditionValue
              );
          }
          return true;
        },
        getRequiredFields() {
            let requiredFields = [];
            for (let rule in this.rules) {
                if (this.rules[rule].length > 0) {
                    let validators = this.rules[rule].filter((el) => el?.required || el?.pattern);
                    if (validators.length) {
                        requiredFields.push(rule);
                    }
                }
            }

            return requiredFields;
        },
    },
};
</script>
